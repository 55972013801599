@config "../../../../../tailwind.api.config.js";

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.bg-darkBlue {
  background-color: #242430;
}
.bg-xDarkBlue {
  background-color: #1a1a22;
}
.bg-qrCode {
  background-color: #1a1a22;
}
.bg-brandOrange {
  background-color: #ff7a64;
}
.bg-altWhite {
  background-color: rgba(251, 251, 250, 0.05);
}
.text-darkBlue {
  color: #242430;
}
.text-xDarkBlue {
  color: #1a1a22;
}
.text-altWhite {
  color: #fbfbfa;
}
.text-brandOrange {
  color: #ff7a64;
}
.text-brandYellow {
  color: #fff06b;
}

/* I really hate global styles but this....makes sense */
div {
  color: white;
}
